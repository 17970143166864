<template>
    <div class="appContentEight">
        <div class="aceBox">
            <!-- 左边图片 -->
            <div class="aceLeftImg">
                <div class="actBg wow animate__animated animate__slideInUp" data-wow-delay="0.2s">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <img
                    class="aceImg wow animate__animated animate__slideInUp"
                    src="http://iv.okvu.cn/vugw/img/page8_2.png"
                    alt=""
                />
                <img
                    class="aceImg wow animate__animated animate__slideInUp"
                    src="http://iv.okvu.cn/vugw/img/page8_1.png"
                    alt=""
                />
                <div class="yun wow animate__animated animate__slideInUp">
                    <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" />
                </div>
                <div class="yun wow animate__animated animate__slideInUp">
                    <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" />
                </div>
                <div class="yun wow animate__animated animate__slideInUp">
                    <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" />
                </div>
            </div>
            <!-- 右边信息 -->
            <div class="aceRightBox">
                <!-- 顶部文字 -->
                <div class="aceTopText">
                    <p class="aceBig wow animate__animated animate__slideInRight">地产类App</p>
                    <p class="aceSmall wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        借力互联网，进军房地产！买房卖房租房，
                    </p>
                    <p class="aceLis wow animate__animated animate__slideInRightt" data-wow-delay="0.2s">
                        定制App开发，支持IOS/安卓双系统
                    </p>
                    <p class="aceList wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                        支持商家端/客户端 双端开发
                    </p>
                    <p class="aceList wow animate__animated animate__slideInRight" data-wow-delay="0.4s">
                        支持App+Pc+Web+微信四合一全网开发
                    </p>
                </div>
                <!-- 图标列表 -->
                <div class="aceBottomList">
                    <div class="ablText">功能</div>
                    <ul>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg" />
                            </div>
                            <!-- <i class="iconfont icon-sousuo"></i> -->
                            <p class="ablP">经纪人管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg2" />
                            </div>
                            <!-- <i class="iconfont icon-sousuo"></i> -->
                            <p class="ablP">经纪人管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg3" />
                            </div>
                            <!-- <i class="iconfont icon-sousuo"></i> -->
                            <p class="ablP">经纪人管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg4" />
                            </div>
                            <!-- <i class="iconfont icon-sousuo"></i> -->
                            <p class="ablP">经纪人管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg5" />
                            </div>
                            <!-- <i class="iconfont icon-sousuo"></i> -->
                            <p class="ablP">经纪人管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg6" />
                            </div>
                            <!-- <i class="iconfont icon-sousuo"></i> -->
                            <p class="ablP">经纪人管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg7" />
                            </div>
                            <!-- <i class="iconfont icon-sousuo"></i> -->
                            <p class="ablP">经纪人管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg8" />
                            </div>
                            <!-- <i class="iconfont icon-sousuo"></i> -->
                            <p class="ablP">经纪人管理</p>
                        </li>
                    </ul>
                </div>
                <!-- 我们的案例 -->
                <div class="aceAlList">
                    <div class="aalText">我们的案例</div>
                    <el-carousel height="100px" arrow="never" :autoplay="true" :interval="2000" indicator-position="none">
                        <el-carousel-item v-for="(item, i) in caseData" :key="i">
                            <ul>
                                <li class="alLi" v-for="el in item" :key="el.id" @click="caseClick(el)">
                                    <img :src="el.logo" class="alImg" />
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- 查看更多案例 -->
                <!-- <div class="aceMoreBtn" @click="goMore">查看更多案例</div> -->
                <div class="ct2More" @click="goMore">
                    <div class="cmTop"></div>
                    <p class="cmp">查看更多案例</p>
                    <div class="cmBottom"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppContentEight",
    props: ["caseData", "caseClick"],

    methods: {
        goMore() {
            this.$router.push("/case");
        },
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.appContentEight {
    width: 100%;
    height: 700px;
    background-color: rgb(73, 135, 210);
    .aceBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边图片
        .aceLeftImg {
            width: 440px;
            height: 420px;
            float: left;
            margin-top: 15%;
            margin-left: 5%;
            position: relative;
            .actBg {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -470px;
                    right: 35px;
                }
            }
            .aceImg:nth-child(2) {
                width: 60%;
                height: 90%;
                margin-top: 20px;
                position: absolute;
                top: 0;
                left: 20%;
                z-index: 2;
            }
            .aceImg:nth-child(3) {
                width: 93%;
                height: 24%;
                position: absolute;
                top: 80%;
                left: 2%;
                z-index: 1;
            }
            .yun {
                overflow: hidden;
                position: absolute;
                z-index: 3;
            }
            .yun:nth-child(4) {
                width: 30%;
                height: 13%;
                top: 31%;
                left: 9%;
                scale: 0.8;
                & > img {
                    position: absolute;
                    top: -748px;
                    left: -400px;
                }
            }
            .yun:nth-child(5) {
                width: 22%;
                height: 13%;
                top: -13%;
                left: 41%;
                scale: 0.8;
                & > img {
                    position: absolute;
                    top: -748px;
                    left: -545px;
                }
            }
            .yun:nth-child(6) {
                width: 18%;
                height: 10%;
                top: 40%;
                left: 72%;
                scale: 0.5;
                & > img {
                    position: absolute;
                    top: -748px;
                    left: -640px;
                }
            }
        }
        // 右边信息
        .aceRightBox {
            width: 50%;
            height: 90%;
            float: right;
            margin-top: 5%;
            // 顶部文字
            .aceTopText {
                width: 80%;
                height: 200px;
                color: white;
                .aceBig {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    font-size: 25px;
                }
                .aceSmall {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 15px;
                    margin-top: 5px;
                }
                .aceList {
                    width: 100%;
                    height: 10px;
                    margin-top: 20px;
                    font-size: 12px;
                }
            }
            // 图标列表
            .aceBottomList {
                width: 70%;
                height: 180px;
                margin-top: 20px;
                color: white;
                .ablText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 130px;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    .ablLi {
                        width: 16%;
                        height: 50%;
                        // margin-left: 1%;
                        .alIcon {
                            width: 45px;
                            height: 70%;
                            margin: 0 auto;
                            overflow: hidden;
                            position: relative;
                            .aiImg {
                                position: absolute;
                                top: -339px;
                                left: -356px;
                            }
                            .aiImg2 {
                                position: absolute;
                                top: -339px;
                                left: -410px;
                            }
                            .aiImg3 {
                                position: absolute;
                                top: -339px;
                                left: -465px;
                            }
                            .aiImg4 {
                                position: absolute;
                                top: -339px;
                                left: -516px;
                            }
                            .aiImg5 {
                                position: absolute;
                                top: -339px;
                                left: -566px;
                            }
                            .aiImg6 {
                                position: absolute;
                                top: -339px;
                                left: -613px;
                            }
                            .aiImg7 {
                                position: absolute;
                                top: -339px;
                                left: -666px;
                            }
                            .aiImg8 {
                                position: absolute;
                                top: -339px;
                                left: -717px;
                            }
                        }
                        .iconfont {
                            width: 100%;
                            height: 50%;
                            font-size: 30px;
                            text-align: center;
                            display: block;
                        }
                        .ablP {
                            width: 100%;
                            height: 20%;
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }
            // 我们的案例
            .aceAlList {
                width: 70%;
                height: 110px;
                margin-top: 20px;
                color: white;
                .aalText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 50px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: left;
                    .alLi {
                        width: 51px;
                        height: 51px;
                        cursor: pointer;
                        transition: 0.5s;
                        border-radius: 10px;
                        margin-right: 5px;
                        overflow: hidden;
                        .alImg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .alLi:hover {
                        transform: translateY(-5px);
                        box-shadow: 1px 1px 10px 1px gray;
                    }
                }
            }
            // 查看更多案例
            // .aceMoreBtn {
            //   width: 150px;
            //   height: 30px;
            //   line-height: 30px;
            //   margin-top: 20px;
            //   text-align: center;
            //   border-radius: 10px;
            //   color: white;
            //   cursor: pointer;
            //   border: 1px solid white;
            //   transition: 0.5s;
            // }
            // .aceMoreBtn:hover {
            //   background-color: #fff;
            //   color: rgb(154, 185, 113);
            // }
            .ct2More {
                width: 160px;
                height: 80px;
                text-align: center;
                margin-top: 10px;
                cursor: pointer;
                font-weight: bold;
                .cmp {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    padding-top: 5px;
                    color: white;
                }
                .cmTop {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmTop::before {
                    border-width: 1px 0 0 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmTop::after {
                    border-width: 1px 1px 0 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmBottom::before {
                    border-width: 0 0 1px 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom::after {
                    border-width: 0 1px 1px 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;
                    transition-duration: 0.3s;
                    transform: translateZ(0);
                }
            }
            .ct2More:hover {
                .cmTop {
                    width: 180px;
                    transform: translateY(-10px) translateX(-10px);
                }
                .cmBottom {
                    width: 180px;
                    transform: translateY(10px) translateX(-10px);
                }
            }
        }
    }
}
</style>
