<template>
    <div class="theApp" style="text-align: left">
        <!-- 左侧导航 -->
        <div class="taLeft">
            <ul>
                <li class="tlLi">
                    <a href="#ac1" :class="{ tlTSize: tts === 1 }">APP导航</a>
                    <div class="tlActive"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac2" :class="{ tlTSize: tts === 2 }">电商类APP</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac3" :class="{ tlTSize: tts === 3 }">餐饮类APP</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac4" :class="{ tlTSize: tts === 4 }">金融类APP</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac5" :class="{ tlTSize: tts === 5 }">医疗类APP</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac6" :class="{ tlTSize: tts === 6 }">旅游类APP</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac7" :class="{ tlTSize: tts === 7 }">家政类APP</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac8" :class="{ tlTSize: tts === 8 }">地产类APP</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac9" :class="{ tlTSize: tts === 9 }">社交类APP</a>
                    <div class="tlActive2"></div>
                </li>
                <li class="tlLi">
                    <a href="#ac10" :class="{ tlTSize: tts === 10 }">行业门户类APP</a>
                    <div class="tlActive2"></div>
                </li>
            </ul>
            <div class="tlBottom">
                <img src="./AppImgs/menu_2-1.png" alt="" class="tlb" />
                <div style="" class="tlb2" :class="{ tlbAn: tlbA }"></div>
                <img src="./AppImgs/menu_1-1.png" alt="" class="tlb" />
                <div style="" class="tlb3" :class="{ tlbAn: tlbA2 }"></div>
            </div>
        </div>
        <!-- 内容一 -->
        <AppContentOne id="ac1"></AppContentOne>
        <!-- 内容二 -->
        <AppContentTwo id="ac2" :caseData="caseData['电商类APP']" :caseClick="caseClick"></AppContentTwo>
        <!-- 内容三 -->
        <AppContentThree id="ac3" :caseData="caseData['餐饮类APP']" :caseClick="caseClick"></AppContentThree>
        <!-- 内容四 -->
        <AppContentFour id="ac4" :caseData="caseData['金融类APP']" :caseClick="caseClick"></AppContentFour>
        <!-- 内容五 -->
        <AppContentFive id="ac5" :caseData="caseData['医疗类APP']" :caseClick="caseClick"></AppContentFive>
        <!-- 内容六 -->
        <AppContentSix id="ac6" :caseData="caseData['旅游类APP']" :caseClick="caseClick"></AppContentSix>
        <!-- 内容七 -->
        <AppContentSeven id="ac7" :caseData="caseData['家政类APP']" :caseClick="caseClick"></AppContentSeven>
        <!-- 内容八 -->
        <AppContentEight id="ac8" :caseData="caseData['地产类APP']" :caseClick="caseClick"></AppContentEight>
        <!-- 内容九 -->
        <AppContentNine id="ac9" :caseData="caseData['社交类APP']" :caseClick="caseClick"></AppContentNine>
        <!-- 内容十 -->
        <AppContentTen id="ac10" :caseData="caseData['行业门户类APP']" :caseClick="caseClick"></AppContentTen>
    </div>
</template>

<script>
import AppContentOne from "./AppContent/AppContentOne.vue";
import AppContentTwo from "./AppContent/AppContentTwo.vue";
import AppContentThree from "./AppContent/AppContentThree.vue";
import AppContentFour from "./AppContent/AppContentFour.vue";
import AppContentFive from "./AppContent/AppContentFive.vue";
import AppContentSix from "./AppContent/AppContentSix.vue";
import AppContentSeven from "./AppContent/AppContentSeven.vue";
import AppContentEight from "./AppContent/AppContentEight.vue";
import AppContentNine from "./AppContent/AppContentNine.vue";
import AppContentTen from "./AppContent/AppContentTen.vue";
import axios from "axios";

export default {
    name: "TheApp",
    data() {
        return {
            tlbA: false,
            tlbA2: false,
            tts: 1,
            // 滚动前，滚动条距文档顶部的距离
            oldScrollTop: 0,
            caseData: {},
            caseClick(al) {
                // if (al.ueditorData == "" || al.ueditorData == "<p><br></p>") return window.open(al.project_url);
                let { ueditorData, project_url } = al;
                if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                    if (!project_url) return;
                    if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                        window.open(project_url);
                    } else {
                        window.open("http://" + project_url);
                    }
                    return;
                }
                this.$router.push({
                    name: "listDetail",
                    params: { id },
                });
            },
        };
    },
    async created() {
        let { data } = await axios.get("/api/project/app");
        for (const k in data) {
            let e = data[k];
            // 超过六个时切割数组为二维数组
            if (e.length > 6) {
                let num = 0;
                let arr = [];
                function jj(l) {
                    num++;
                    l -= 6;
                    if (l >= 6) return jj(l);
                    return l;
                }
                jj(e.length);
                for (let i = 0; i < num + 1; i++) {
                    let star = i * 6,
                        end = i * 6 + 5;
                    if (i == num) end = e.length - 1;
                    arr.push([...e.slice(star, end)]);
                }
                data[k] = arr;
            } else {
                data[k] = [[...e]];
            }
        }
        // console.log(data);
        this.caseData = data;
    },
    components: {
        AppContentOne,
        AppContentTwo,
        AppContentThree,
        AppContentFour,
        AppContentFive,
        AppContentSix,
        AppContentSeven,
        AppContentEight,
        AppContentNine,
        AppContentTen,
    },
    mounted() {
        window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
        let dom = document.getElementsByClassName("tlLi");
        Object.values(dom).map((item, index) => {
            item.addEventListener("click", () => {
                let tla = document.querySelector(".tlActive");
                if (index !== 0) {
                    tla.style.opacity = 0;
                }
            });
        });
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scrollHandle);
    },
    methods: {
        scrollHandle(e) {
            let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
            // 滚动条距文档顶部的距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.oldScrollTop;
            // 更新——滚动前，滚动条距文档顶部的距离
            this.oldScrollTop = scrollTop;
            let tla = document.querySelector(".tlActive");
            let tla2 = document.querySelectorAll(".tlActive2");
            // console.log();
            Object.values(tla2).map(item => {
                item.style.opacity = 0;
            });
            if (scrollStep < 0) {
                this.tlbA = true;
                setTimeout(() => {
                    this.tlbA = false;
                }, 300);
            } else {
                this.tlbA2 = true;
                setTimeout(() => {
                    this.tlbA2 = false;
                }, 300);
            }
            if (top < 700) {
                tla.style.opacity = 1;
                tla2[0].style.opacity = 0;
                this.tts = 1;
            } else if (top <= 1300) {
                tla.style.opacity = 0;
                tla2[0].style.opacity = 1;
                tla2[1].style.opacity = 0;
                this.tts = 2;
            } else if (top <= 2000) {
                tla2[0].style.opacity = 0;
                tla2[1].style.opacity = 1;
                tla2[2].style.opacity = 0;
                this.tts = 3;
            } else if (top <= 2700) {
                tla2[1].style.opacity = 0;
                tla2[2].style.opacity = 1;
                tla2[3].style.opacity = 0;
                this.tts = 4;
            } else if (top <= 3400) {
                tla2[2].style.opacity = 0;
                tla2[3].style.opacity = 1;
                tla2[4].style.opacity = 0;
                this.tts = 5;
            } else if (top <= 4100) {
                tla2[3].style.opacity = 0;
                tla2[4].style.opacity = 1;
                tla2[5].style.opacity = 0;
                this.tts = 6;
            } else if (top <= 4800) {
                tla2[4].style.opacity = 0;
                tla2[5].style.opacity = 1;
                tla2[6].style.opacity = 0;
                this.tts = 7;
            } else if (top <= 5500) {
                tla2[5].style.opacity = 0;
                tla2[6].style.opacity = 1;
                tla2[7].style.opacity = 0;
                this.tts = 8;
            } else if (top <= 6200) {
                tla2[6].style.opacity = 0;
                tla2[7].style.opacity = 1;
                tla2[8].style.opacity = 0;
                this.tts = 9;
            } else if (top < 6900) {
                tla2[7].style.opacity = 0;
                tla2[8].style.opacity = 1;
                this.tts = 10;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.theApp {
    min-width: 1423px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    // 右侧导航
    .taLeft {
        width: 120px;
        height: 500px;
        position: fixed;
        right: 5%;
        top: 100px;
        z-index: 9;
        ul {
            width: 100%;
            height: 90%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            border-right: 1px dashed white;
            .tlLi {
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                position: relative;
                text-align: center;
                a {
                    color: white;
                }
                .tlActive,
                .tlActive2 {
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: #7781f1;
                    transition: 0.5s;
                    position: absolute;
                    right: -8px;
                    top: 4px;
                    transition: 0.8s;
                }
                .tlActive2 {
                    background-color: #7781f1;
                    opacity: 0;
                }
            }
            .tlTSize {
                font-size: 17px;
                font-weight: 900;
                color: #7781f1 !important;
            }
        }
        .tlBottom {
            width: 100%;
            height: 10%;
            position: relative;
            .tlb {
                margin-left: 15px;
                margin-top: 10px;
            }
            .tlb2 {
                width: 10px;
                height: 10px;
                position: absolute;
                top: 25%;
                left: 31%;
                overflow: hidden;
                background: url("./AppImgs/menu_3%E2%80%941.png") no-repeat;
                transition: 0.5s;
            }
            .tlb3 {
                width: 10px;
                height: 10px;
                position: absolute;
                top: 62%;
                left: 30%;
                overflow: hidden;
                background: url("./AppImgs/menu_3%E2%80%941.png") no-repeat bottom;
                transition: 0.5s;
            }
            .tlbAn {
                opacity: 0;
            }
        }
    }
}
</style>
