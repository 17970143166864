<template>
    <div class="appContentSeven">
        <div class="acSBox">
            <!-- 左边信息 -->
            <div class="acSLeftBox">
                <!-- 顶部文字 -->
                <div class="acSTopText">
                    <p class="acSBig wow animate__animated animate__slideInLeft">家政类App</p>

                    <p class="acSSmall wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        线上线下无缝对接，客户家政轻松牵手
                    </p>
                    <p class="acSList wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        定制App开发，支持IOS/安卓双系统
                    </p>
                    <p class="acSList wow animate__animated animate__slideInLeft" data-wow-delay="0.3s">
                        支持商家端/客户端 双端开发
                    </p>
                    <p class="acSList wow animate__animated animate__slideInLeft" data-wow-delay="0.4s">
                        支持App+Pc+Web+微信四合一全网开发
                    </p>
                </div>
                <!-- 图标列表 -->
                <div class="acSBottomList">
                    <div class="ablText">功能</div>
                    <ul>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg" />
                            </div>
                            <!-- <i class="iconfont icon-kefu"></i> -->
                            <p class="ablP">家政预约</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg2" />
                            </div>
                            <!-- <i class="iconfont icon-kefu"></i> -->
                            <p class="ablP">家政预约</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg3" />
                            </div>
                            <!-- <i class="iconfont icon-kefu"></i> -->
                            <p class="ablP">家政预约</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg4" />
                            </div>
                            <!-- <i class="iconfont icon-kefu"></i> -->
                            <p class="ablP">家政预约</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg5" />
                            </div>
                            <!-- <i class="iconfont icon-kefu"></i> -->
                            <p class="ablP">家政预约</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg6" />
                            </div>
                            <!-- <i class="iconfont icon-kefu"></i> -->
                            <p class="ablP">家政预约</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg7" />
                            </div>
                            <!-- <i class="iconfont icon-kefu"></i> -->
                            <p class="ablP">家政预约</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg8" />
                            </div>
                            <!-- <i class="iconfont icon-kefu"></i> -->
                            <p class="ablP">家政预约</p>
                        </li>
                    </ul>
                </div>
                <!-- 我们的案例 -->
                <div class="acSAlList">
                    <div class="aalText">我们的案例</div>
                    <el-carousel height="100px" arrow="never" :autoplay="true" :interval="2000" indicator-position="none">
                        <el-carousel-item v-for="(item, i) in caseData" :key="i">
                            <ul>
                                <li class="alLi" v-for="el in item" :key="el.id" @click="caseClick(el)">
                                    <img :src="el.logo" class="alImg" />
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- 查看更多案例 -->
                <!-- <div class="acSMoreBtn" @click="goMore">查看更多案例</div> -->
                <div class="ct2More" @click="goMore">
                    <div class="cmTop"></div>
                    <p class="cmp">查看更多案例</p>
                    <div class="cmBottom"></div>
                </div>
            </div>
            <!-- 右边图片 -->
            <div class="acSRightImg">
                <div class="actBg wow animate__animated animate__slideInUp" data-wow-delay="0.2s">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <img
                    style="top: 170px; height: auto; z-index: 99"
                    class="acSImg wow animate__animated animate__slideInUp"
                    src="https://iv.okvu.cn/vugw/img/page7_2.png"
                    alt=""
                />
                <div
                    style="
                        width: 372px;
                        height: auto;
                        background: red;
                        top: 0;
                        perspective: 500px;
                        position: absolute;
                        transform-style: preserve-3d;
                    "
                >
                    <img
                        class="photoImg"
                        style="top: 220px; position: absolute; width: 372px; left: 34px"
                        src="http://iv.okvu.cn/vugw/img/photo_image.png"
                        alt=""
                    />
                </div>
                <div id="sevenImageBox">
                    <div style="top: 134px; left: 30px" class="imageBoxSeven">
                        <img style="left: -308px; top: -540px" src="http://iv.okvu.cn/vugw/img/vu618.png" />
                    </div>
                    <div style="top: 82px; left: 90px; width: 45px; height: 45px" class="imageBoxSeven">
                        <img style="left: -454px; top: -623px" src="http://iv.okvu.cn/vugw/img/vu618.png" />
                    </div>
                    <div style="top: 134px; left: 145px; width: 35px; height: 35px" class="imageBoxSeven">
                        <img style="left: -538px; top: -438px" src="http://iv.okvu.cn/vugw/img/vu618.png" />
                    </div>
                    <div style="top: 82px; left: 202px; width: 85px; height: 85px" class="imageBoxSeven">
                        <img style="left: -111px; bottom: -44px" src="http://iv.okvu.cn/vugw/img/vu618.png" />
                    </div>
                    <div style="top: 82px; left: 310px; width: 60px; height: 60px" class="imageBoxSeven">
                        <img style="left: -308px; top: -540px" src="http://iv.okvu.cn/vugw/img/vu618.png" />
                    </div>
                    <div style="top: 153px; left: 283px; width: 40px; height: 40px" class="imageBoxSeven">
                        <img style="left: -654px; top: -490px" src="http://iv.okvu.cn/vugw/img/vu618.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppContentSeven",
    props: ["caseData", "caseClick"],

    mounted() {
        function show() {
            let dom = document.getElementById("sevenImageBox");
            let domTop = dom.getBoundingClientRect().top;
            let image = document.getElementsByClassName("photoImg");
            // console.log(domTop, '(sevenBox)');
            if (domTop < 530) {
                // dom.className = "imageBoxMarginSeven";
                // dom.style.animation = ''
                dom.style.marginTop = "0";
                image[0].className = image[0].className + " " + "rateClassSeven";
                dom.style.opacity = "1";
            } else {
                dom.style.marginTop = "50px";
                dom.style.opacity = "0.5";
                image[0].className = "photoImg";
            }
            window.removeEventListener("scroll", show);
        }
        window.addEventListener("scroll", show);
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    methods: {
        goMore() {
            this.$router.push("/case");
        },
    },
};
</script>

<style lang="less" scoped>
@keyframes setRate {
    30% {
        transform: rotateX(60deg);
    }
    50% {
        transform: rotateX(-60deg);
    }
    70% {
        transform: rotateX(60deg);
    }
    // rate
}
.rateClassSeven {
    animation: setRate 1s 1;
}
.photoImg:hover {
    // transform: rotateX(60deg);
    // animation: setRate 1s infinite;
}
// .photoImg {
//   transition: 0.5s;
// }
// @keyframes setMargin {
//   0% {
//     margin-top: ;
//   }
// }
// .imageBoxMarginSeven {
// }
#sevenImageBox {
    margin-top: 50px;
    transition: margin 1s, opacity 1s;
    // transition:  1s;
    position: absolute;
    top: 0;
    opacity: 0.5;
}
.imageBoxSeven {
    width: 60px;
    height: 60px;
    position: absolute;
    overflow: hidden;
}
.imageBoxSeven img {
    width: 45em;
    position: absolute;
}
.appContentSeven {
    width: 100%;
    height: 700px;
    background-color: rgb(253, 167, 58);
    .acSBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边信息
        .acSLeftBox {
            width: 50%;
            height: 90%;
            float: left;
            margin-top: 5%;
            margin-left: 5%;
            // 顶部文字
            .acSTopText {
                width: 80%;
                height: 200px;
                color: white;
                .acSBig {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    font-size: 25px;
                }
                .acSSmall {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 15px;
                    margin-top: 5px;
                }
                .acSList {
                    width: 100%;
                    height: 10px;
                    margin-top: 20px;
                    font-size: 12px;
                }
            }
            // 图标列表
            .acSBottomList {
                width: 70%;
                height: 180px;
                margin-top: 20px;
                color: white;
                .ablText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 130px;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    .ablLi {
                        width: 16%;
                        height: 50%;
                        // margin-left: 1%;
                        .alIcon {
                            width: 45px;
                            height: 70%;
                            margin: 0 auto;
                            overflow: hidden;
                            position: relative;
                            .aiImg {
                                position: absolute;
                                top: -280px;
                                left: -1039px;
                            }
                            .aiImg2 {
                                position: absolute;
                                top: -339px;
                                left: 6px;
                            }
                            .aiImg3 {
                                position: absolute;
                                top: -339px;
                                left: -40px;
                            }
                            .aiImg4 {
                                position: absolute;
                                top: -339px;
                                left: -93px;
                            }
                            .aiImg5 {
                                position: absolute;
                                top: -339px;
                                left: -145px;
                            }
                            .aiImg6 {
                                position: absolute;
                                top: -339px;
                                left: -200px;
                            }
                            .aiImg7 {
                                position: absolute;
                                top: -339px;
                                left: -250px;
                            }
                            .aiImg8 {
                                position: absolute;
                                top: -339px;
                                left: -302px;
                            }
                        }
                        .iconfont {
                            width: 100%;
                            height: 50%;
                            font-size: 30px;
                            text-align: center;
                            display: block;
                        }
                        .ablP {
                            width: 100%;
                            height: 20%;
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }
            // 我们的案例
            .acSAlList {
                width: 70%;
                height: 110px;
                margin-top: 20px;
                color: white;
                .aalText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 50px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: left;
                    .alLi {
                        width: 51px;
                        height: 51px;
                        cursor: pointer;
                        transition: 0.5s;
                        border-radius: 10px;
                        margin-right: 5px;
                        overflow: hidden;
                        .alImg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .alLi:hover {
                        transform: translateY(-5px);
                        box-shadow: 1px 1px 10px 1px gray;
                    }
                }
            }
            // 查看更多案例
            // .acSMoreBtn {
            //   width: 150px;
            //   height: 30px;
            //   line-height: 30px;
            //   margin-top: 20px;
            //   text-align: center;
            //   border-radius: 10px;
            //   color: white;
            //   cursor: pointer;
            //   border: 1px solid white;
            //   transition: 0.5s;
            // }
            // .acSMoreBtn:hover {
            //   background-color: #fff;
            //   color: rgb(138, 193, 238);
            // }
            .ct2More {
                width: 160px;
                height: 80px;
                text-align: center;
                margin-top: 10px;
                cursor: pointer;
                font-weight: bold;
                .cmp {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    padding-top: 5px;
                    color: white;
                }
                .cmTop {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmTop::before {
                    border-width: 1px 0 0 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmTop::after {
                    border-width: 1px 1px 0 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmBottom::before {
                    border-width: 0 0 1px 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom::after {
                    border-width: 0 1px 1px 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;
                    transition-duration: 0.3s;
                    transform: translateZ(0);
                }
            }
            .ct2More:hover {
                .cmTop {
                    width: 180px;
                    transform: translateY(-10px) translateX(-10px);
                }
                .cmBottom {
                    width: 180px;
                    transform: translateY(10px) translateX(-10px);
                }
            }
        }
        // 右边图片
        .acSRightImg {
            width: 440px;
            height: 420px;
            float: left;
            margin-top: 15%;
            margin-right: 5%;
            position: relative;
            .actBg {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -470px;
                    right: 35px;
                }
            }
            .acSImg {
                width: 60%;
                height: 90%;
                margin-top: 20px;
                position: absolute;
                top: 0;
                left: 20%;
            }
        }
    }
}
</style>
