<template>
    <div class="appContentSix">
        <div class="acsBox">
            <!-- 左边图片 -->
            <div class="acsLeftImg">
                <div class="actBg wow animate__animated animate__slideInUp" data-wow-delay="0.2s">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <img
                    style="width: 80%; height: auto; top: 85px; left: 69px"
                    class="acsImg wow animate__animated animate__slideInUp"
                    src="https://iv.okvu.cn/vugw/img/page6_1.png"
                    alt=""
                />
                <div id="imageBoxMax">
                    <div style="top: 262px; left: 10px" class="imageBoxSix">
                        <img src="http://iv.okvu.cn/vugw/img/vu618.png" style="top: -481px; left: -580px" />
                    </div>
                    <div style="top: 200px; left: 284px; width: 105px" class="imageBoxSix">
                        <img src="http://iv.okvu.cn/vugw/img/vu618.png" style="top: -545px; left: -537px" />
                    </div>
                    <div style="top: 57px; left: 140px; width: 156px; height: 100px" class="imageBoxSix">
                        <img src="http://iv.okvu.cn/vugw/img/vu1212.png" style="top: -835px; left: -375px" />
                    </div>
                    <div style="top: 175px; left: 44px; width: 100px; height: 70px" class="imageBoxSix">
                        <img src="http://iv.okvu.cn/vugw/img/vu1212.png" style="top: -849px; left: -280px" />
                    </div>
                </div>
            </div>
            <!-- 右边信息 -->
            <div class="acsRightBox">
                <!-- 顶部文字 -->
                <div class="acsTopText">
                    <p class="acsBig wow animate__animated animate__slideInRight">旅游类App</p>
                    <p class="acsSmall wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        布局清晰，操作简单，手指一点，出行无忧
                    </p>
                    <p class="acsList wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                        定制App开发，支持IOS/安卓双系统
                    </p>
                    <p class="acsList wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                        支持商家端/客户端 双端开发
                    </p>
                    <p class="acsList wow animate__animated animate__slideInRight" data-wow-delay="0.4s">
                        支持App+Pc+Web+微信四合一全网开发
                    </p>
                </div>
                <!-- 图标列表 -->
                <div class="acsBottomList">
                    <div class="ablText">功能</div>
                    <ul>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg" />
                            </div>
                            <!-- <i class="iconfont icon-fangdichanjianzhu"></i> -->
                            <p class="ablP">酒店预订</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg2" />
                            </div>
                            <!-- <i class="iconfont icon-fangdichanjianzhu"></i> -->
                            <p class="ablP">酒店预订</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg3" />
                            </div>
                            <!-- <i class="iconfont icon-fangdichanjianzhu"></i> -->
                            <p class="ablP">酒店预订</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg4" />
                            </div>
                            <!-- <i class="iconfont icon-fangdichanjianzhu"></i> -->
                            <p class="ablP">酒店预订</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg5" />
                            </div>
                            <!-- <i class="iconfont icon-fangdichanjianzhu"></i> -->
                            <p class="ablP">酒店预订</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg6" />
                            </div>
                            <!-- <i class="iconfont icon-fangdichanjianzhu"></i> -->
                            <p class="ablP">酒店预订</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg7" />
                            </div>
                            <!-- <i class="iconfont icon-fangdichanjianzhu"></i> -->
                            <p class="ablP">酒店预订</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.okvu.cn/vugw/img/vu618.png" alt="" class="aiImg8" />
                            </div>
                            <!-- <i class="iconfont icon-fangdichanjianzhu"></i> -->
                            <p class="ablP">酒店预订</p>
                        </li>
                    </ul>
                </div>
                <!-- 我们的案例 -->
                <div class="acsAlList">
                    <div class="aalText">我们的案例</div>
                    <el-carousel height="100px" arrow="never" :autoplay="true" :interval="2000" indicator-position="none">
                        <el-carousel-item v-for="(item, i) in caseData" :key="i">
                            <ul>
                                <li class="alLi" v-for="el in item" :key="el.id" @click="caseClick(el)">
                                    <img :src="el.logo" class="alImg" />
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- 查看更多案例 -->
                <!-- <div class="acsMoreBtn" @click="goMore">查看更多案例</div> -->
                <div class="ct2More" @click="goMore">
                    <div class="cmTop"></div>
                    <p class="cmp">查看更多案例</p>
                    <div class="cmBottom"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppContentSix",
    props: ["caseData", "caseClick"],

    methods: {
        goMore() {
            this.$router.push("/case");
        },
    },
    mounted() {
        function show() {
            let dom = document.getElementsByClassName("acsBox");
            let domTop = dom[0].getBoundingClientRect().top;
            let domBox = document.getElementById("imageBoxMax");
            let s = false;
            if (domTop < 300) {
                if (s) return;
                s = true;
                domBox.className = domBox.className + " " + "scaleBoxClass";
                let divArr = document.getElementsByClassName("imageBoxSix");
                Object.values(divArr).map(item => {
                    item.className = item.className + " " + "ratoClass";
                });
            }
            window.removeEventListener("scroll", show);
        }
        window.addEventListener("scroll", show);
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
@keyframes setScale {
    0% {
        scale: 0.1;
        opacity: 0;
    }
    100% {
        scale: 1;
        margin-top: 0;
        opacity: 1;
    }
}
@keyframes setRato {
    100% {
        transform: rotate(360deg);
    }
}
.ratoClass {
    animation: setRato 2s 1;
}
.scaleBoxClass {
    animation: setScale 2s 1;
}
#imageBoxMax {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.imageBoxSix {
    width: 60px;
    height: 60px;
    overflow: hidden;
    position: absolute;
}
.imageBoxSix img {
    width: 45em;
    position: absolute;
}
.appContentSix {
    width: 100%;
    height: 700px;
    background-color: rgb(1, 183, 96);
    .acsBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边图片
        .acsLeftImg {
            width: 440px;
            height: 420px;
            float: left;
            margin-top: 15%;
            margin-left: 5%;
            position: relative;
            .actBg {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -470px;
                    right: 35px;
                }
            }
            .acsImg {
                width: 60%;
                height: 90%;
                margin-top: 20px;
                position: absolute;
                top: 0;
                left: 20%;
            }
        }
        // 右边信息
        .acsRightBox {
            width: 50%;
            height: 90%;
            float: right;
            margin-top: 5%;
            // 顶部文字
            .acsTopText {
                width: 80%;
                height: 200px;
                color: white;
                .acsBig {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    font-size: 25px;
                }
                .acsSmall {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 15px;
                    margin-top: 5px;
                }
                .acsList {
                    width: 100%;
                    height: 10px;
                    margin-top: 20px;
                    font-size: 12px;
                }
            }
            // 图标列表
            .acsBottomList {
                width: 70%;
                height: 180px;
                margin-top: 20px;
                color: white;
                .ablText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 130px;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    .ablLi {
                        width: 16%;
                        height: 50%;
                        // margin-left: 1%;
                        .alIcon {
                            width: 45px;
                            height: 70%;
                            margin: 0 auto;
                            overflow: hidden;
                            position: relative;
                            .aiImg {
                                position: absolute;
                                top: -280px;
                                left: -571px;
                            }
                            .aiImg2 {
                                position: absolute;
                                top: -280px;
                                left: -637px;
                            }
                            .aiImg3 {
                                position: absolute;
                                top: -280px;
                                left: -705px;
                            }
                            .aiImg4 {
                                position: absolute;
                                top: -280px;
                                left: -766px;
                            }
                            .aiImg5 {
                                position: absolute;
                                top: -280px;
                                left: -816px;
                            }
                            .aiImg6 {
                                position: absolute;
                                top: -280px;
                                left: -873px;
                            }
                            .aiImg7 {
                                position: absolute;
                                top: -280px;
                                left: -932px;
                            }
                            .aiImg8 {
                                position: absolute;
                                top: -280px;
                                left: -986px;
                            }
                        }
                        .iconfont {
                            width: 100%;
                            height: 50%;
                            font-size: 30px;
                            text-align: center;
                            display: block;
                        }
                        .ablP {
                            width: 100%;
                            height: 20%;
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }
            // 我们的案例
            .acsAlList {
                width: 70%;
                height: 110px;
                margin-top: 20px;
                color: white;
                .aalText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 50px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: left;
                    .alLi {
                        width: 51px;
                        height: 51px;
                        cursor: pointer;
                        transition: 0.5s;
                        border-radius: 10px;
                        margin-right: 5px;
                        overflow: hidden;
                        .alImg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .alLi:hover {
                        transform: translateY(-5px);
                        box-shadow: 1px 1px 10px 1px gray;
                    }
                }
            }
            // 查看更多案例
            // .acsMoreBtn {
            //   width: 150px;
            //   height: 30px;
            //   line-height: 30px;
            //   margin-top: 20px;
            //   text-align: center;
            //   border-radius: 10px;
            //   color: white;
            //   cursor: pointer;
            //   border: 1px solid white;
            //   transition: 0.5s;
            // }
            // .acsMoreBtn:hover {
            //   background-color: #fff;
            //   color: rgb(209, 170, 124);
            // }
            .ct2More {
                width: 160px;
                height: 80px;
                text-align: center;
                margin-top: 10px;
                cursor: pointer;
                font-weight: bold;
                .cmp {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    padding-top: 5px;
                    color: white;
                }
                .cmTop {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmTop::before {
                    border-width: 1px 0 0 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmTop::after {
                    border-width: 1px 1px 0 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmBottom::before {
                    border-width: 0 0 1px 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom::after {
                    border-width: 0 1px 1px 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;
                    transition-duration: 0.3s;
                    transform: translateZ(0);
                }
            }
            .ct2More:hover {
                .cmTop {
                    width: 180px;
                    transform: translateY(-10px) translateX(-10px);
                }
                .cmBottom {
                    width: 180px;
                    transform: translateY(10px) translateX(-10px);
                }
            }
        }
    }
}
</style>
